
export default {
  layout: 'replace',
  beforeMount() {
    // let lang = this.$cookies.get('lang');
    // if(!lang) {
    //   let browserLanguage = (navigator.language || navigator.browserLanguage).toLowerCase();
    //   let locales = this.$store.state.locales;
    //   let langStr = browserLanguage.split('-')[0];
    //   let index = locales.findIndex(item => item.language.includes(langStr));
    //   if(index > -1) {
    //     lang = locales[index].language;
    //   }else{
    //     lang = 'en';
    //   }
    // }
    // this.$store.commit('SET_LANG', lang);
    // this.$i18n.locale = lang;

    // if(this.$device.isMobile) {
    //   window.location.href = this.$i18n.path('', 'm');
    // }else{
    //   window.location.href = this.$i18n.path('');
    // }
  }
}
